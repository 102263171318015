import React from "react";
import ContentCard from "../domain/ContentCard";
import Head from "../components/Head";
import Layout from "../domain/Layout";

const CardTemplate = ({
    pageContext: {
        edge: {
            node: {
                frontmatter: {
                    category,
                    date,
                    images,
                    keywords,
                    source,
                    subTitle,
                    text,
                    title,
                },
            },
        },
    },
}: any) => {
    console.log(images);

    return (
        <Layout>
            <Head title={title} keywords={keywords} />
            <ContentCard
                title={title}
                subtitle={subTitle}
                text={text}
                pictures={images}
                category={category}
                layout={1}
                source={source}
                date={date}
            />
            <div
                className="fb-comments"
                data-href="https://intresting-web-page.pages.dev/dummy-slug-4"
                data-width="500"
                data-numposts="5"
                data-colorscheme="dark"
            ></div>
        </Layout>
    );
};

export default CardTemplate;
